









import { Component, Vue } from 'vue-property-decorator'
import Page from '@/components/Page.vue'
import FaqItem from '@/components/FaqItem.vue';

const questions = {
  rider:[
   {
      question:"Wann kann man über die 50:50 Taxi App mitfahren?",
      answer:"Alle zwischen 16 und 25 Jahren können jeweils freitags und samstags sowie unmittelbar vor und an allen gesetzlichen Feiertagen des Bundes und des Landes Brandenburg von 20 bis 8 Uhr am Folgetag für den halben Preis Taxi fahren. An Silvester sogar bis 12 Uhr am Neujahrstag."
   },
   {
      question:"Bis wann kann man Fahrten stornieren?",
      answer:"Fahrten können in der Regel bis 3 Stunden vor Fahrtbeginn storniert werden. Aber bitte denke daran, dass auch die Taxiunternehmen planen müssen und nutze diese Möglichkeit nur, wenn es nicht anders geht."
   },
   {
      question:"Wer übernimmt die Taxi Fahrtkosten?",
      answer:"50 % des Fahrpreises zahlt der Fahrgast direkt im Taxi und 50% übernimmt das Land Brandenburg."
   },
   {
      question:"Muss ich mein Alter bei der Anmeldung als Mitfahrer verifizieren?",
      answer:"Beim Anlegen des Nutzerkontos ist das Geburtsdatum anzugeben. Als Fahrgast musst Du dein Alter bei Fahrtantritt mit einem amtlichen Ausweisdokument (Reisepass oder Personalausweis) verifizieren können."
   },
   {
      question:"Sind Fahrten in ein anderes Bundesland möglich?",
      answer:"Es sind Fahrten von und nach Berlin eingeschlossen. Hier gilt, dass entweder Start oder Ziel der Fahrt im Land Brandenburg liegen müssen."
   },
   {
      question:"Was ist der Unterschied zwischen Fahrten mit einem Taxi und einem Mietwagen?",
      answer:"Der Fahrpreis für Taxen wird nach der Fahrt am Taxameter abgelesen. Er richtet sich nach dem jeweils geltenden Taxitarif. Der Fahrpreis für Mietwagen wird im Voraus verhandelt und festgelegt. Mietwagenfahrten muss der Fahrgast vorher bestätigen."
   },
   {
      question:"Wie setzt sich der Fahrpreis zusammen?",
      answer:"Der Fahrpreis berechnet sich wie eine normale Taxifahrt nach den gefahrenen km und den weiteren Rahmenbedingungen des geltenden Taxitarifs. Das Land Brandenburg übernimmt beim 50:50 Taxi die Hälfte des Fahrpreises."
   },
   {
      question:"Was mache ich, wenn ich mein Passwort vergessen habe?",
      answer:"Auf der Anmeldeseite befindet sich ein Button \"Passwort vergessen\". Dort kannst du draufklicken und anschließend die bei der Registrierung verwendete E-Mail eintragen. Du erhältst einen Link, über den du Dein neues Passwort setzen kannst."
   },
   {
      question:"Wie lösche ich meinen Account?",
      answer:"Im Menü befindet sich oberhalb des Logout-Buttons ein Button \"Account löschen\". Klicke diesen an und es öffnet sich ein Fenster, in welchem Du die Löschung des Accounts bestätigen oder abbrechen kannst."
   },
   {
      question:"Wie viele Personen können mitfahren?",
      answer:"Dies hängt von den verfügbaren Taxen ab. Bei Großraumtaxen beträgt die maximale Personenanzahl 8 Personen. Gib bitte bei der Fahrtanfrage die voraussichtliche Zahl der Mitfahrer an, um ein entsprechend großes Taxi zu bekommen."
   },
   {
      question:"Wie funktioniert der Chat?",
      answer:"Sobald Deine Fahrt bestätigt ist, kannst Du mit dem Fahrer über die integrierte Chatfunktion kommunizieren."
   },
   {
      question:"Kann ich einen Chat stumm schalten?",
      answer:"Auf der Chatseite kann der Chat über das Klingel-Icon oben rechts stummgeschaltet oder die Stummschaltung aufgehoben werden. Ist der Chat stummgeschaltet, erhältst Du keine Push-Benachrichtigung über eingegangene Nachrichten."
   }
  ],
  driver: [
   {
      question:"Wie kann ich mich als Fahrer registrieren?",
      answer:"Ihr Taxiunternehmen muss auf der Webseite https://www.5050brandenburg.de/register-company registriert sein. Nach erfolgreicher Registrierung und Anmeldung kann dort das Fahrpersonal angelegt werden."
   },
   {
      question:"Wann kann man über die 50:50 Taxi App mitfahren?",
      answer:"Alle zwischen 16 und 25 Jahren können jeweils freitags und samstags sowie unmittelbar vor und an allen gesetzlichen Feiertagen des Bundes und des Landes Brandenburg von 20 bis 8 Uhr am Folgetag für den halben Preis Taxi fahren. An Silvester sogar bis 12 Uhr am Neujahrstag."
   },
   {
      question:"Bis wann kann man Fahrten stornieren?",
      answer:"Fahrgäste können eine angenommene Fahrt in der Regel bis 3 Stunden vor Fahrtbeginn stornieren. Fahrer können eine Fahrt in der Regel nicht stornieren. Sind Fahrgäste nicht beförderungsfähig oder erscheinen nicht zur vereinbarten Zeit am vereinbarten Ort, so kann die Fahrt im Nachgang storniert und ein entsprechender Grund hinterlegt werden."
   },
   {
      question:" Was mache ich, wenn die Fahrt nicht zustande kommt?",
      answer:" Gründe hierfür können etwa eine fehlende Beförderungsunfähigkeit und das nicht Erscheinen zum Fahrtantritt sein. Es gelten hierbei die gleichen Grundsätze, wie bei Fahrten ohne das 50:50 Taxi. Wurde die Fahrt nach der in der App hinterlegten Startzeit nicht begonnen, so wird abgefragt, ob die Fahrt noch durchgeführt wird. Wird die Fahrt nicht mehr durchgeführt, ist einer der oben aufgeführten Gründe anzugeben und die Fahrt wird storniert."
   },
   {
      question:"Wer übernimmt die Fahrtkosten?",
      answer:"50 % des Fahrpreises zahlt der Fahrgast direkt im Taxi und 50% übernimmt das Land Brandenburg. Die Abrechnung erfolgt mit dem hinterlegten Unternehmen."
   },
   {
      question:"Wie verifiziert der Fahrgast sein Alter?",
      answer:"Bestehen Zweifel an der Berechtigung zur Nutzung des 50:50 Taxis, so können Sie verlangen, dass Fahrgäste ihr Alter bei Fahrtantritt mit einem gültigen Ausweisdokument verifizieren, etwas einem Reisepass oder Personalausweis."
   },
   {
      question:"Sind Fahrten in ein anderes Bundesland möglich?",
      answer:"Fahrten in ein anderes Bundesland mit der Vergünstigung des 50:50 Taxis sind grundsätzlich nicht möglich. Eine Ausnahme gilt für das Bundesland Berlin, allerdings müssen entweder der Zielort oder der Ausgangsort in Brandenburg liegen."
   },
   {
      question:"Wie viele Fahrten können im Voraus angefragt werden?",
      answer:"Je Nutzer können 3 Fahrten im Voraus angefragt werden. Sobald eine Fahrt erfolgt ist, kann eine weitere angefragt werden."
   },
   {
      question:"Wie setzt sich der Fahrpreis zusammen?",
      answer:"Der Fahrpreis richtet sich nach dem geltenden Taxi-Tarif und ist vom Taxameter zu übernehmen. Das Land Brandenburg übernimmt jedoch die Hälfte des Fahrpreises. Die Abrechnung erfolgt mit dem auf der Webseite https://www.5050brandenburg.de/register-company hinterlegten Unternehmen."
   },
   {
      question:"Was mache ich, wenn ich mein Passwort vergessen habe?",
      answer:" Auf der Anmeldeseite befindet sich ein Button \"Passwort vergessen\". Dort können Sie draufklicken und anschließend die bei der Registrierung verwendete E-Mail eintragen. Wir senden Ihnen einen Link zu, über den ein neues Passwort gesetzt werden kann."
   },
   {
      question:"Wie lösche ich meinen Account?",
      answer:"Im Menü befindet sich oberhalb des Logout Buttons ein Button \"Account löschen\". Wird dieser geklickt, öffnet sich ein Fenster, in welchem die Löschung des Accounts bestätigt oder abgebrochen werden kann."
   },
   {
      question:"Wie viele Personen können mitfahren?",
      answer:"Im Rahmen des 50:50 Taxi Projekts genügt es, wenn eine Person berechtigt ist, das Angebot anzunehmen. Bei Fahrtanfrage kann der Fahrgast bis zu 8 Personen angeben."
   },
   {
      question:"Wie funktioniert der Chat?",
      answer:"Sobald eine Fahrt bestätigt ist kann über die integrierte Chatfunktion kommuniziert werden."
   },
   {
      question:"Kann ich einen Chat stumm schalten?",
      answer:"Auf der Chatseite kann der Chat über das Klingel-Icon -oben rechts- stummgeschaltet oder die Stummschaltung aufgehoben werden. Ist der Chat stummgeschaltet, werden keine Push-Benachrichtigungen mehr versendet."
   },
   {
      question:"Wie bekomme ich die 50% Fahrtkosten vom Land erstattet?",
      answer:"Die mit dem 50:50 Taxi durchgeführten Fahrten werden in der App gesammelt. Am Ende des Monats erhält Ihr Taxiunternehmen den Gesamtbetrag von der Taxigenossenschaft Potsdam e.G. erstattet."
   }
  ],
  company: [
   {
      question:"Wie kann ich als Taxiunternehmen teilnehmen?",
      answer:"Ihr Taxiunternehmen muss auf der Webseite https://www.5050brandenburg.de registriert sein. Nach erfolgreicher Registrierung und Freischaltung kann dort Ihr Fahrpersonal angelegt werden."
   },
   {
      question:" Welche Dokumente werden für die Registrierung Ihres Unternehmens benötigt?",
      answer:"Um die Registrierung und Freischaltung durchzuführen , benötigen Sie ein Dokument, das Ihre Tätigkeit als Taxi-Unternehmen nachweist (z. B. einen Konzessionsauszug). Dieses Dokument muss bei der Registrierung hochgeladen werden. "
   },
  {
      question:"Was mache ich, wenn ich mein Passwort vergessen habe?",
      answer:"Auf der Anmeldeseite befindet sich ein Button \"Passwort vergessen\". Dort können Sie draufklicken und anschließend die bei der Registrierung verwendete E-Mail-Adresse eintragen. Wir senden Ihnen einen Link zu, über den ein neues Passwort gesetzt werden kann."
   },
   {
      question:"Wie lege ich mein Fahrpersonal an?",
      answer:"Nach der Anmeldung im Portal können Sie unter dem Menüpunkt \"Fahrer\" neue Taxifahrer eintragen. Geben Sie hierzu den Vornamen, Nachnamen und eine individuelle E-Mail-Adresse für die jeweilige Person ein. Anschließend können Sie über den Button eine Einladung an Ihren Fahrer senden. Der Fahrer erhält eine E-Mail mit einem Link zur App und seinen Zugangsdaten. Hiermit kann er die App direkt nutzen."
   },
   {
      question:"Wer übernimmt die Fahrtkosten?",
      answer:"50 % des Fahrpreises zahlt der Fahrgast direkt im Taxi und 50% übernimmt das Land Brandenburg. Die Abrechnung erfolgt über die von Ihnen hinterlegte Bankverbindung des Unternehmens."
   },
   {
      question:"Wie bekomme ich die 50% Fahrtkosten vom Land erstattet?",
      answer:"Die Taxigenossenschaft Potsdam e.G. übernimmt im Auftrag des Ministeriums die buchhalterische Abwicklung und Kostenerstattung für das 50:50-Taxi-Projekt. Die mit dem 50:50 Taxi durchgeführten Fahrten werden in der App gesammelt und monatsweise an Ihr Taxiunternehmen erstattet."
   },
   {
      question:"Wie setzt sich der Fahrpreis zusammen?",
      answer:"Der Fahrpreis richtet sich nach dem geltenden Taxi-Tarif und ist vom Taxameter zu übernehmen. Das Land Brandenburg übernimmt die Hälfte des Fahrpreises, maximal jedoch 75 Euro. Der Restbetrag ist direkt im Taxi vom Fahrgast zu entrichten. Die Abrechnung erfolgt mit dem auf der Webseite https://www.5050brandenburg.de hinterlegten Unternehmen."
   },
   {
      question:"Sind Fahrten in ein anderes Bundesland möglich?",
      answer:"Fahrten in ein anderes Bundesland mit der Vergünstigung des 50:50 Taxis sind grundsätzlich nicht möglich. Eine Ausnahme gilt für das Bundesland Berlin, allerdings müssen entweder der Zielort oder der Ausgangsort in Brandenburg liegen."
   },
   {
      question:"Wohin kann ich mich bei Fragen wenden?",
      answer:"Bei Fragen wenden Sie sich bitte per E-Mail an das Ministerium für Infrastruktur und Landesplanung des Landes Brandenburg, Referat 41 unter folgender E-Mail-Adresse: ref41.mil@mil.brandenburg.de"

   },
   {
      question:"Wie lösche ich meinen Account?",
      answer:"Im Menü befindet sich oberhalb des Logout Buttons ein Button \"Account löschen\". Wird dieser geklickt, öffnet sich ein Fenster, in welchem die Löschung des Accounts bestätigt oder abgebrochen werden kann."
   },
  ],
}

@Component({
  components: {
    FaqItem,
    Page
  }
})
export default class FAQView extends Vue {
  // @ts-ignore
  faqQuestions = questions[this.$route.params.type] || questions['company']
}
